import { useSelector } from 'react-redux';

const useGlobalSettings = (template_page_settings = null) => {
    const template_settings = useSelector(state => state.globalReducer.template_settings);
    const page_settings =  useSelector(state => state.globalReducer.template_page_content);

    const getSetting = (key, default_flag = true) => {
        return template_settings?.[key] || default_flag
    }

    const getPageSetting = (page, key, default_flag = true) => {
        return page_settings?.[page]?.[key] || default_flag
    }

    const getTemplatePageSetting = (key, default_flag = true) => {
        return template_page_settings?.[key] || default_flag
    }

    return {getSetting, getPageSetting, getTemplatePageSetting}
}

export default useGlobalSettings;