import Typography from '@mui/material/Typography'

const P = ({children, ...props}) => <Typography component="p" variant="p" {...props}>{children}</Typography>
export const Strong = ({children, ...props}) => <Typography component="strong" variant="strong" {...props}>{children}</Typography>
export const Span = ({children, ...props}) => <Typography component="span" variant="span" {...props}>{children}</Typography>
export const ErrorSpan = ({ children, ...props }) => (
    <Typography component="span" variant="span" {...props} color="error.main">
        {children}
    </Typography>
);

export default P;