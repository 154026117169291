import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import useWindowSize from '@components/_hooks/useWindowSize';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { Span } from '../Reusables/Typography/P';
import { useTheme } from '@emotion/react';
import { alpha } from '@mui/material/styles';
import useGlobalSettings from '@components/_hooks/useGlobalSettings';
import PageTitle from '../Reusables/PageTitle';

const DefaultButton = dynamic(() => import('../Reusables/Theme/DefaultButton'));
const HomeBanner = dynamic(() => import('./HomeBanner'));

const HomeCategories = (props) => {
    const {
        categories,
        home_grid_columns,
        includeBanner = true,
        custom = false,
        template_page_settings = [],
        vanillaText = false
    } = props;

    
    const {isMobile} = useWindowSize();
    const {getTemplatePageSetting} = useGlobalSettings(template_page_settings);

    const renderGridItems = (all = false, start_index, end_index = 2) => {
        const _cat = {};
        Object.keys(categories).forEach(k => {
            if(all){
                _cat[k] = categories[k];
            }
            else if ((parseInt(k) <= end_index) && (parseInt(k) >= start_index)) {
                _cat[k] = categories[k];
            } 
            // else {
            //     _hidden[k] = categories[k];
            // }
    });

    const DefaultText = ({cat}) => (
        <DefaultButton
            sx={{
                minWidth: {
                    sm: '150px',
                    md: '175px'
                },
                borderRadius: '3px',
                height: '35px',
                ...(custom && {
                    width: '90%',
                    height: '70px',
                    border: '4px solid white',
                    borderRadius: '15px',
                    fontSize: '92px',
                    backgroundColor: alpha(theme.palette.primary.main, 0.9),
                })
            }}
            color="primary"
            variant="contained"
        >
            <Span
                sx={{
                    ...(custom && {
                        fontSize: {
                            xl: getTemplatePageSetting('category_subtitle_font_size', '26px'),
                            lg: '18px',
                            md: '16px'
                        },
                        letterSpacing: 0
                    })
                }}
            >
                {custom ? cat.subtitle : cat.name}
            </Span>
        </DefaultButton>
    )

    const VanillaText = ({cat}) => (
        <Box
            className="text-center text-vertical justify-center uppercase f-large p-1 category-box"
            sx={{
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
                width: '100%',
                height: '35px',
            }}
        >
            {cat.name}
        </Box>
    )

        const theme = useTheme();

        return (
            <>
                {Object.entries(_cat).map(([key, category], idx) => (
                    category.map((_cat, index) => (
                    <Grid item xs={4} sm={4} md={4} lg={parseInt(home_grid_columns)}  key={index}>
                        <Link href={`/${_cat.slug}`}>
                            {
                                custom && (
                                    <PageTitle  as="h3" className="text-center d-block m-0 dark-grey uppercase fw-semi-bold fs-sm-18 fs-xs-12">{_cat.name}</PageTitle>
                                )
                            }
                            <Box 
                                className="position-relative pointer" 
                                sx={{
                                    width: '100%', 
                                    height: {
                                        xs: '150px',
                                        lg: getTemplatePageSetting('category_tile_height', '300px')
                                    },
                                    overflow: 'hidden',
                                    transition: 'all 0.4s ease',
                                    ':hover': {
                                        backgroundColor: 'black',
                                        'button': {
                                            backgroundColor: 'primary.dark'
                                        }
                                    },
                                    '.category-image': {
                                        transition: 'all 0.4s ease',
                                    },
                                    ':hover .category-image': {
                                        transition: 'all 0.4s ease',
                                        transform: 'scale(1.05)',
                                        opacity: '0.6'
                                    },
                                    ':hover .category-box': {
                                        backgroundColor: 'primary.dark'
                                    }
                                }}
                            >
                                {!isMobile && (
                                    <Box
                                        className="d-flex justify-center w-100"
                                        sx={{
                                            zIndex: 5,
                                            position: 'absolute',
                                            bottom: {
                                                sm: 5,
                                                md: vanillaText ? 0 : 20
                                            }
                                        }}
                                    >
                                        {vanillaText ? (<VanillaText cat={_cat}/> ) : <DefaultText cat={_cat} />}
                                    </Box>

                                )}
                                    <Image 
                                        className="category-image"
                                        src={_cat.image}
                                        alt={`Image ${index + 1}`}
                                        fill 
                                        priority 
                                        style={{ objectFit: 'cover' }}
                                        // sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw"
                                    />
                            </Box>
                        </Link>

                        {isMobile && (
                            <div className="d-flex justify-center">
                                    <Typography color="primary" sx={{fontWeight: 500}} className="text-center fs-xs-12">
                                        {custom ? _cat.subtitle : _cat.name}
                                        {/* {item.name == 'Browse by Event' ? 'By Event' : item.name} */}
                                    </Typography>
                            </div>
                        )}
                        </Grid>
                    ))
                ))}
            </>
        )
    };
  

    return (
        <Grid className="position-relative" container 
            rowSpacing={{
                xl: 3,
                lg: 3,
                md: 2,
                sm: 1,
                xs: 1
            }}
            columnSpacing={{
                xl: 3,
                lg: 3,
                md: 2,
                sm: 1,
                xs: 1,
            }}
        >
            {!isMobile && renderGridItems(false, 1, 2)}
            {
                (includeBanner && !isMobile) && (
                    <Grid item xs={12}>
                        <HomeBanner/>
                    </Grid>
                )
            }
            {!isMobile && renderGridItems(false, 3, 3)}
            {/* Change to false, 3, 4 if all 10 categories are to be shown. */}
            {isMobile && renderGridItems(true, 1, 2)}

      </Grid>
    )
}

export default HomeCategories