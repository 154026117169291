import Typography from "@mui/material/Typography"


const defaultStyles = {
    fontSize: {
        xs: '22px',
        sm: '26px',
        md: '30px'
    },
    margin: {
        xs: '20px 0px 8px 0px',
        md: '28px 0px 8px 0px',
    }
}

const PageTitle = (props) => {
    const {
        sx = {},
        children,
        ...rest
    } = props

    return (
        <Typography
            sx={{
                ...defaultStyles,
                ...sx,
            }}
            {...rest}
        >
            {children}
        </Typography>
    )
}

export default PageTitle